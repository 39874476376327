.x-form-control-label
  display: inline-flex
  align-items: flex-start
  color: var(--on-surface-dark)
  &__control
    flex: 0 0 auto
  &__label
    +preset(2)
    margin: 0 0 0 16px

x-form-control-label
  display: block
  font-size: 0
  line-height: 0
  &[disabled]
    .x-form-control-label
      cursor: not-allowed
      &__label
        color: var(--on-surface-dark-50)
