import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-form-control-label',
  styleUrl: 'x-form-control-label.sass',
})
export class XFormControlLabel {
  @Prop() disabled: boolean;

  render() {
    return (
      <Host>
        <label class="x-form-control-label">
          <span class="x-form-control-label__control">
            <slot name="control"></slot>
          </span>
          <span class="x-form-control-label__label">
            <slot></slot>
          </span>
        </label>
      </Host>
    );
  }
}
